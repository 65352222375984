<template>
  <ValidationObserver>
    <form @submit.prevent="saveData">
      <b-card title="Update Building Material">
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Name Material</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.name"
              placeholder="Lantai Keramik Low Grade"
              required
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-4 col-form-label">Unit:</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.unit"
              placeholder="m2"
              required
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-4 col-form-label" for="provinsi">Category:</label>
          <div class="col">
            <v-select
              id="provinsi"
              v-model="formPayload.category_uuid"
              placeholder="-- Pilih Category --"
              label="name"
              :options="category"
              :reduce="(category) => category.uuid"
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-4 col-form-label" for="provinsi"
            >Sub Category:</label
          >
          <div class="col">
            <v-select
              id="provinsi"
              v-model="formPayload.sub_category_uuid"
              placeholder="-- Pilih Sub Category --"
              label="name"
              :options="subcategory"
              :reduce="(subcategory) => subcategory.uuid"
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-4 col-form-label">Harga Lantai 2</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.prices[1].price"
              type="number"
              placeholder=""
              required
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-4 col-form-label">Harga Lantai 1</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.prices[0].price"
              type="number"
              placeholder=""
              required
            />
          </div>
        </div>
      </b-card>
      <b-col cols="12" class="p-0 mt-2">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
        &nbsp; &nbsp;
        <a
          @click="handleBackButton()"
          variant="secondary"
          class="btn waves-effect waves-float waves-light btn-secondary"
        >
          Cancel
        </a>
      </b-col>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile,
  BButton,
  BSpinner,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { VueEditor } from "vue2-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"],
  },
});
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormFile,
    BFormInput,
    BFormTextarea,
    VueEditor,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  data() {
    return {
      category: [],
      subcategory: [],
      required,
      isLoading: false,
      formPayload: {
        name: "",
        unit: "",
        prices: [
          {
            floor_key: "floor1",
            price: "",
          },
          {
            floor_key: "floor2",
            price: "",
          },
        ],
      },
      image_thumbnail: "",
      bannerImage: "",
      fileVendorImage: [],
      filesThumbnail: [],
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic"],
        [false, false],
        [false],
        [false],
      ],
      snowOption: {
        theme: "snow",
      },
      validations: "",
    };
  },
  setup(props) {
    return {
      successNotification,
      errorNotification,
    };
  },

  watch: {
    "formPayload.category_uuid": function(value) {
      this.subcategoryName(value);
    },
  },

  created() {
    this.getData();
    this.categoryName();
  },

  methods: {
    categoryName() {
      this.$http.get("/admin/building-category/parent").then((response) => {
        this.category = response.data.data;
      });
    },
    subcategoryName(value) {
      this.$http
        .get("/admin/building-category/child", {
          params: {
            parent_uuid: value,
          },
        })
        .then((response) => {
          this.subcategory = [];
          this.subcategory = response.data.data;
        });
    },
    handleBackButton() {
      history.back();
    },
    getData() {
        const params = this.$route.params.slug;
        this.$http.get("/admin/building-material/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
          this.formPayload.category_uuid = this.formPayload.sub_category.parent.uuid; 
          this.formPayload.sub_category_uuid = this.formPayload.sub_category.uuid;
          this.formPayload.prices[0].floor_key = this.formPayload.prices[0].floor.key;
          this.formPayload.prices[0].price = this.formPayload.prices[0].price;
          this.formPayload.prices[1].floor_key = this.formPayload.prices[1].floor.key;
          this.formPayload.prices[1].price = this.formPayload.prices[1].price;
        })
        .catch((error) => {
        });
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (key != "prices") {
          form.append(key, this.formPayload[key]);
        }
      }
      if (this.formPayload.prices.length) {
        for (let index = 0; index < this.formPayload.prices.length; index++) {
          const element = this.formPayload.prices[index];
          for (const key in element) {
            if (Object.hasOwnProperty.call(element, key)) {
              const items = element[key];
              form.append(`prices[${index}][${key}]`, items);
            }
          }
        }
      }
      return form;
    },
    saveData() {
      const slugs = this.$route.params.slug
      var form = this.preparePayload();
      this.isLoading = true;
      form.append("_method", "PATCH");
      this.$http
        .post(`/admin/building-material/${slugs}`, form, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          successNotification(this, "Success", "Building Material Updated!");
          this.$router.push({ name: "building-material" });
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
            errorNotification(this, "Nama", this.validations.name.join(" "));
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
